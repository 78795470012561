@import './index.css';
@import './styles/_var.scss';
@import './reset.scss';

:global {
  html,
  body {
    // min-width: 1400px;
    background-color: #f3f3f3ff !important;
  }

  // table {
  //   table-layout: fixed;
  // }

  .mr10 {
    margin-right: 10px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .link {
    color: #409eff;
  }

  .main-page-container {
    // height: 1000px;
    margin-bottom: 60px;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 30px 16px;

    .search-section {
      box-sizing: border-box;
      border-bottom: 1px solid #b5b8c9;
      padding-bottom: 20px;

      .ant-btn {
        margin-right: 10px;
      }
    }
  }

  .is-content-block {
    padding: 20px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    min-height: 500px;

    .title {
      h3 {
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 20px;

        @extend .text-color-primary;
      }
    }
  }

  .is-module {
    width: 100%;

    @extend .border-one;

    .is-module-title {
      font-size: 18px;
      font-weight: 500;
      padding: 10px 15px;
      background: #ededed;

      @extend .text-color-primary;
    }
  }

  .text-strong {
    font-weight: bold;
  }

  .text-color-common {
    color: #606266;
  }

  .text-color-primary {
    color: #303133;
  }

  .text-color-minor {
    color: $gray;
  }

  .text-color-placeholder {
    color: #c0c4cc;
  }

  .color-red {
    color: $red;
  }

  .color-green {
    color: $green;
  }

  .color-gray {
    color: $gray;
  }

  .color-blue {
    color: $blue;
  }

  .border-one {
    border: 1px solid $border-color-one;
  }

  .border-one-left {
    border-left: 1px solid $border-color-one;
  }

  .border-one-right {
    border-right: 1px solid $border-color-one;
  }

  .border-one-top {
    border-top: 1px solid $border-color-one;
  }

  .border-one-bottom {
    border-bottom: 1px solid $border-color-one;
  }

  .border-two {
    border: 1px solid #e4e7ed;
  }

  .border-three {
    border: 1px solid #ebeef5;
  }

  .text-center {
    text-align: center;
  }

  .layout-main-contant {
    padding: 15px;
    position: relative;
    flex: 1;
    // min-height: calc(100% - 96px);
    margin: 24px;
  }
  .layout-main-crumbs {
    padding: 15px;
    padding-left: 35px;
    position: relative;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .font-weight-normal {
    font-weight: normal;
  }

  html {
    height: 100%;
  }

  .basic-error-label {
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  /*修改滚动条样式*/
  .layout-aside::-webkit-scrollbar {
    width: 0px;
    height: 4px;
    /**/
  }
  .layout-aside::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  .layout-aside::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }
  .layout-aside::-webkit-scrollbar-thumb:hover {
    background: #333;
  }
  .layout-aside::-webkit-scrollbar-corner {
    background: #179a16;
  }

  .layout-aside {
    height: calc(100vh - 53px);
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 55;
    overflow-y: auto;
    color: rgb(191, 203, 217);
    transition: 0.3s all;

    .layout-aside-logo {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;
      padding: 30px 0;
      justify-content: center;

      img {
        margin-bottom: 20px;
        overflow: hidden;
      }

      span {
        display: inline-block;
        color: #fff;
        font-size: 18px;
        font-weight: normal;
      }
    }

    .layout-aside-logo-small {
      width: 100%;
      text-align: center;
      padding: 30px 0;

      img {
        width: 60%;
        border-radius: 50% 50%;
      }
    }
  }

  .layout-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // min-width: 1000px;
    // display: block;
    height: 50px;
    line-height: 50px;
    text-align: center;
    z-index: 51;
    min-width: 1000px;
    transition: 0.3s margin-left;
    // padding-left: 200px;
  }

  .layout-header {
    min-width: 1000px;
    position: absolute;
    top: 0;
    left: 200px;
    right: 0;
    z-index: 50;
    background: #002140;
    padding: 0 50px 0 10px;
    transition: 0.3s all;
    box-shadow: 0 2px 0 #ddd;

    > h3 {
      width: 100%;
      font-size: 18px;
      padding: 20px 0 5px;
    }

    .layout-header-breadcrumb {
      display: flex;
      flex-grow: 1;
      align-items: start;
      justify-content: space-between;
    }

    .layout-header-user {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      color: #8fa4b0;
      span {
        padding: 10px 0;
      }
    }
  }

  .layout-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    transition: 0.3s padding-left;
    overflow: hidden;
    z-index: 52;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: scroll;
  }

  .ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
  .ant-form-inline {
    > .form_item_warp {
      margin-bottom: 24px;
    }
  }
  .mh100 {
    min-height: 100% !important;
  }
  // 页面宽度
  .w1400 {
    width: 1400px !important;
    margin: 0 auto;
  }
  .w990 {
    width: 990px;
    margin: 0 auto;
  }
  .overflowHidden {
    overflow: hidden;
  }
  // 颜色
  .bcfff {
    background-color: #fff;
  }
  .cSucc {
    color: #52c41aff;
  }
  .cWarn {
    color: #faad14ff;
  }
  .cErr {
    color: #ff4d4fff;
  }
  // 字体
  .fwb {
    font-weight: bold;
  }
  .ft14 {
    font-size: 14px;
  }
  .ft16 {
    font-size: 14px;
  }
  // 行内对齐方式
  .tacr {
    text-align: right;
  }
  .tacc {
    text-align: center;
  }
  .tacl {
    text-align: left;
  }
}
