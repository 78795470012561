.appWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100%;
}

.intlSelect:global.ant-select {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 100px;
}