.container {
  // width: 1440px;
  margin: 0 auto;
  overflow: auto;
  background-color: #F7FAFC;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.activeGoodsBg {
  background: url('./images/activeBg_icon.png');
  height: 100%;
}
