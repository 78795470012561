:global {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  blockquote,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  pre,
  form,
  fieldset,
  legend,
  button,
  input,
  textarea,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  /** 设置默认字体 **/
  body,
  button,
  input,
  select,
  textarea {
    font-size: 14px;
    line-height: 1.5;
    font-family: "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
  }

  body {
    height: 100%;
    width: 100%;
    display: flex;
    // background: #F3F3F3 !important;
  }

  html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .page,
  #root {
    height: 100%;
    width: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  address,
  cite,
  dfn,
  em,
  var {
    font-style: normal;
  }

  /* 将斜体扶正 */
  code,
  kbd,
  pre,
  samp {
    font-family: courier new, courier, monospace;
  }

  /* 统一等宽字体 */
  small {
    font-size: 12px;
  }

  /* 小于 12px 的中文很难阅读，让 small 正常化 */

  /** 重置列表元素 **/
  ul,
  ol {
    list-style: none;
  }

  /** 重置文本格式元素 **/
  a {
    text-decoration: none;
    color: $blue;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: $gray;
    }
  }

  * {
    //box-sizing: border-box;
  }

  /** 重置表单元素 **/
  legend {
    color: #000;
  }

  /* for ie6 */
  fieldset,
  img {
    border: 0;
  }

  /* img 搭车：让链接里的 img 无边框 */
  button,
  input,
  select,
  textarea {
    font-size: 100%;
  }

  /* 使得表单元素在 ie 下能继承字体大小 */

  /* 注：optgroup 无法扶正 */

  /** 重置表格元素 **/
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* 清除浮动 */
  .clear::after {
    content: '\20';
    display: block;
    height: 0;
    clear: both;
  }

  .clear {
    *zoom: 1;
  }
}
